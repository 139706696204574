import { spawn } from "@redux-saga/core/effects"
import employee, { addEvaluationSaga, designationSaga, evaluationSaga } from './employeeSaga'
import authSaga, { autoAuthSaga } from "./AuthSaga"
export default function* rootSaga() {
    yield spawn(employee)
    yield spawn(designationSaga)
    yield spawn(authSaga)
    yield spawn(evaluationSaga)
    yield spawn(addEvaluationSaga)
    yield spawn(autoAuthSaga)
}